@import "../../../../../../styles/vars_mixins";

.profile-info {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  @apply bg-dark-200;
  opacity: 0.8;
  border-radius: 50%;
  margin-top: 2px;
  border: 1px solid;
  @apply border-gray-warm;

  &:hover {
    opacity: 1;
  }

  svg {
    height: 20px;
    margin-bottom: 2px;
    @apply text-white;
  }
}

.profile-info-tooltip-content {
  text-align: left;

  p {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    svg {
      height: 12px;
      margin-left: 4px;
      @apply text-white;
    }

    small {
      margin-left: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
