@import "../../../../../../styles/vars_mixins";

.profile-cover {
  height: 156px;
  position: relative;
  width: 100%;

  .cover-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
    height: 100%;
    position: absolute;
    width: 100%;

    @include themify(day) {
      @apply bg-light-300;
    }

    @include themify(night) {
      @apply bg-dark-default;
    }
  }
}
